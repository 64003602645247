.dark {
    .ant-pagination {
        .ant-pagination-item {
            @apply bg-gray-600 text-white;
        }

        .ant-pagination-item-active {
            overflow: hidden;
            @apply border-white;

            a {

                @apply bg-gray-600 text-white;
            }
        }
    }

    .ant-select-selector {
        background: rgb(75 85 99) !important;
        @apply text-white;
    }


    .ant-table-thead tr th {
        background: black !important;
        color: white;
    }

    .ant-table-tbody tr td {
        background: rgb(13 17 23) !important;
        color: white;

        .ant-table-row-expand-icon {
            background: black;
        }
    }
}