@tailwind base;
@tailwind components;
@tailwind utilities;

.text-black,
.ck-editor {
  width: 100% !important;
}

.shapedBackground {
  overflow-x: hidden;

  background-image: url("./assets/backgroundShape.png");
}

.french {
  background-image: linear-gradient(90deg, blue, white, red);
  padding: 0 16px;
  border-radius: 8px;
  /* background-clip: text;
  color: transparent; */
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-image: url("./assets/heroImage.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  /* background: linear-gradient(90deg,
      rgb(0, 38, 84) 0%,
      rgb(255, 255, 255) 50%,
      rgb(237, 41, 57) 100%); */
}

.box div {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: transparent;
  /* border: 6px solid rgba(255, 255, 255, 0.9); */
}

.box div:nth-child(1) {
  top: 12%;
  left: 42%;
  animation: animate 10s linear infinite;
}

.box div:nth-child(2) {
  top: 70%;
  left: 50%;
  animation: animate 7s linear infinite;
}

.box div:nth-child(3) {
  top: 17%;
  left: 6%;
  animation: animate 9s linear infinite;
}

.box div:nth-child(4) {
  top: 20%;
  left: 60%;
  animation: animate 10s linear infinite;
}

.box div:nth-child(5) {
  top: 67%;
  left: 10%;
  animation: animate 6s linear infinite;
}

.box div:nth-child(6) {
  top: 80%;
  left: 70%;
  animation: animate 12s linear infinite;
}

.box div:nth-child(7) {
  top: 60%;
  left: 80%;
  animation: animate 15s linear infinite;
}

.box div:nth-child(8) {
  top: 32%;
  left: 25%;
  animation: animate 16s linear infinite;
}

.box div:nth-child(9) {
  top: 90%;
  left: 25%;
  animation: animate 9s linear infinite;
}

.box div:nth-child(10) {
  top: 20%;
  left: 80%;
  animation: animate 5s linear infinite;
}

@keyframes animate {
  0% {
    transform: scale(0) translateY(0) rotate(0);
    opacity: 1;
  }

  100% {
    transform: scale(1.3) translateY(-90px) rotate(360deg);
    opacity: 0;
  }
}


/* reviews */
.reviews-auto-fit {
  margin-inline: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 2rem;
  justify-items: center;
}